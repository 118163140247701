@tailwind base;
@tailwind components;
@tailwind utilities;

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:disabled span {
  opacity: 1;
}
